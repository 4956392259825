
<template>
    <div v-if="dsItemsProperties.data && (dsItemsProperties.data.length > 0 && !readOnly)">

        <template v-for="(item,index) in dsItemsProperties.data" >
            <h4 v-if="showHeader(index)" class="mt-3">{{ $t(item.GroupByHeader) }}</h4>
            <div class="col-12 mt-3" v-if="item.DataType == 'text'">
                <label class="form-label" :title="$t(item.Caption)" >{{ $t(item.Caption) }} {{ $t(item.Required ? '*' : '') }}</label>
                <input type="text" class="form-control" v-model="item.Value">
            </div>

            <div class="col-12 mt-3" v-if="item.DataType == 'bool'">
                <input :id="item.ID" type="checkbox" class="pr-1" v-model="item.Value" true-value="True" false-value="False">
                <label :for="item.ID" class="ms-1">{{ $t(item.Caption) }}</label>
            </div>

            <div class="col-12 mb-3" v-if="item.DataType == 'date'">
                <label class="form-label">{{ $t(item.Caption) }}</label>
                <ODatePicker  
                    v-model="item.DateValue"
                    format="Short Date"
                    :clearable="false"
                    clearableFromPicker
                    :showInputIcon="true"
                    class="form-select"/>
            </div>

            <div class="col-12 mb-3" v-if="item.DataType == 'datetime'">
                <label class="form-label">{{ $t(item.Caption) }}</label>
                <ODatePicker  
                    v-model="item.DateTimeValue"
                    format="General Date Short Time"
                    timepicker
                    :clearable="false"
                    clearableFromPicker
                    :showInputIcon="true"
                    class="form-select"/>
            </div>

        </template>

    </div> 
</template>

<script setup>
    import { computed, onUnmounted } from 'vue';
    import { getOrCreateDataObject, deleteDataObject, getDataObjectById } from 'o365.vue.ts';
    import utils from 'o365.modules.utils.js';

    const props = defineProps({
        readOnly: {
            type: Boolean,
            default: false,
        },
        dataSource: Object
    });

    const emit = defineEmits(["propertySaved", "propertyDeleted"]);    

    const dsItemsProperties = props.dataSource

    function showHeader(index){
        if(index > 0){
            return dsItemsProperties.data[index].GroupByHeader != dsItemsProperties.data[index-1].GroupByHeader;
        }else if(dsItemsProperties.data[index].GroupByHeader){
            return true;
        }else{
            return false;
        }
    }

</script>